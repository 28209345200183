var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"IndexBranch mt-2",class:{ open: _vm.open }},[_c('i',{staticClass:"fa fa-caret-right pointer tree-toggle",attrs:{"aria-hidden":"true","data-cy":("IndexBranch-toggle--" + (_vm.index.name))},on:{"click":_vm.onToggleBranchClicked}}),_c('router-link',{staticClass:"tree-item truncate mt-2",class:{ active: _vm.isIndexActive(_vm.index.name) },attrs:{"data-cy":("Treeview-item-index-link--" + (_vm.index.name)),"title":_vm.index.name,"to":{ name: 'Collections', params: { indexName: _vm.index.name } }}},[_c('i',{staticClass:"fa fa-database",attrs:{"aria-hidden":"true"}}),_c('HighlightedSpan',{attrs:{"value":_vm.index.name,"filter":_vm.filter}}),(_vm.index.collectionsCount !== undefined)?[_vm._v(" ("+_vm._s(_vm.index.collectionsCount)+")")]:_vm._e()],2),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.collectionsFetched)?_c('div',{staticClass:"collections"},[(_vm.orderedFilteredCollections.length)?_vm._l((_vm.orderedFilteredCollections),function(collection){return _c('div',{key:((collection.name) + "-" + (collection.type)),staticClass:"tree-item truncate mt-2",class:{ active: _vm.isCollectionActive(_vm.index.name, collection.name) },attrs:{"data-cy":("Treeview-item--" + (collection.name)),"title":collection.name}},[(collection.isRealtime())?[_c('i',{staticClass:"fa fa-bolt ml-1 mr-2",attrs:{"aria-hidden":"true","title":"Volatile collection"}}),_c('router-link',{attrs:{"to":{
              name: 'WatchCollection',
              params: {
                indexName: _vm.index.name,
                collectionName: collection.name
              }
            }}},[_c('HighlightedSpan',{attrs:{"value":collection.name,"filter":_vm.filter}})],1)]:[_c('i',{staticClass:"fa fa-th-list",attrs:{"aria-hidden":"true","title":"Persisted collection"}}),_c('router-link',{attrs:{"to":{
              name: 'DocumentList',
              params: {
                indexName: _vm.index.name,
                collectionName: collection.name
              }
            }}},[_c('HighlightedSpan',{attrs:{"value":collection.name,"filter":_vm.filter}})],1)]],2)}):[_c('span',{staticClass:"text-muted"},[_vm._v("no collections")])],(_vm.showMoreCollectionsDisplay)?_c('b-link',{staticClass:"tree-item truncate",on:{"click":_vm.toggleShowMoreCollections}},[(!_vm.showMoreCollections)?_c('u',[_vm._v("Show More")]):_c('u',[_vm._v("Show only results")])]):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }